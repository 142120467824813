

body {
    margin: 0;
    font-family: le-monde-livre-classic-byol, "Times New Roman", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #EDF3F8;
    font-size: 18px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media all and (min-width: 320px) {
    h1 {
        font-size: 1.8rem;
    }

    h2 {
        margin-top: 3rem;
        font-size: 1.6rem;
    }
}

@media all and (min-width: 640px) {
    h1 {
        font-size: 2.6rem;
    }

    h2 {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
}


a {
    outline: none;
    text-decoration: none;
    padding: 2px;
}

a:link {
    font-weight: bold;
    color: #502F6A;
    border-bottom: thin #502F6A solid;
}

a:visited {
    color: #9E72C0;
}

a:focus {
    background-color: rgba(206, 185, 223, 0.25);
    border-bottom: none;
    color: #502F6A;
}

a:hover {
    background-color: rgba(206, 185, 223, 0.25);
    color: #502F6A;
}

a:active {
    color: #1B1023;
    background-color: transparent;
    border-bottom: thin #1B1023;
}
