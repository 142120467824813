.siteMast {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.bgFill {
    background-size: cover;
}

.logo a {
    background-color: transparent;
}


.btnMenu {
    display: inline-block;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    padding: 12px;
}

.nav-block a {
    text-decoration: none;
    border-bottom: none;
    font-family: aileron, sans-serif;
}

.nav-block a:visited {
    color: #502F6A;
}


.menu ol {
    list-style-type: none;
    text-align: right;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 0.8rem;
}

.menu-item {
    padding: 1rem 2rem;
}

.nav-mobile {
    display: flex;
    align-items: flex-end;
}

.logo {
    box-sizing: border-box;
    padding: 12px 0 12px 12px;
}

.logo img {
    max-width: 72px;
}

.btnMenu {
    display: none;
}

@media all and (min-width: 320px) {
    .logo img {
        max-width: 112px;
    }
}

@media all and (min-width: 432px) {

    .menu {
        padding: 0 0 12px 0;
    }

    .menu ol {
        font-size: 1rem;
    }

    .logo {
        padding: 0 0 24px 24px;
    }

    .logo img {
        max-width: 122px;
    }
}

@media all and (min-width: 652px) {

    .menu {
        padding: 0
    }

    .menu ol {
        display: flex;
        height: 100%;
        padding: 0 2rem 0 0;
        align-items: center;
    }

    .nav-mobile {
        flex: 1;
    }

    .menu-item {
        display: inline-block;
        padding: 0 0 0 2rem;

    }

    .logo {
        padding: 24px 2rem;
    }

    .logo img {
        max-width: 64px;
    }
}