body.lightbox-visible {
    height: 100vh;
    overflow-y: hidden;
}

body.lightbox-visible .lightbox {
    visibility: visible;
}

.lightbox {
    position: fixed;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    font-family: Aileron, sans-serif;
}

.lightbox-inner {
    box-sizing: border-box;
    width: 100%;
}

.lightbox-buttons {
    text-align: right;
}

.lightbox-button {
    max-height: 24px;
    max-width: 24px;
    padding: 2rem;
}

.lightbox-context {
    visibility: hidden;
}

.lightbox .media-wrap > * {
    max-height: 80vh;
}

#lightbox-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 3rem 0 2rem 0;
    font-weight: bold;
    color: white;
    padding: 0 0 1.5rem 0;
    border-bottom: thin solid #CEB9DF;
}

@media all and (min-width: 1200px) {
    .lightbox-inner {
        display: flex;
        padding: 0 2rem;
        justify-content: center;
    }

    .lightbox-buttons {
        margin: 0 0 5vh 0;
    }

    .lightbox-content {
        display: flex;
        justify-content: center;
        height: calc(100vh - 4rem);
    }

    .lightbox .lightbox-context {
        visibility: visible;
        max-width: 312px;
        padding: 0 2rem 0 4rem;
        color: #CEB9DF;
    }

    .lightbox .media-wrap {

    }
}
