.studies h3 {
    color: #502F6A;
    margin: 0 0 1.5rem 0;
    font-size: 1.75rem;
}

.intro {
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;
}

.intro-graphic {
    max-width: 156px;
    margin-right: 2.5rem;
}

.intro-graphic img {
    width: 100%;
}

.intro-text em {
    color: #9E72C0;
    font-style: normal;
    margin: 0;
}

.intro-text h1 {
    color: #502F6A;
    margin: -0.5rem 0 0 0;
}

.intro-text p {
    margin: 0;
    font-size: 16px;
}

.card {
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color, border-color;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
}

.studies h2 {
    font-size: 1.5rem;
    color: #9E72C0;
    font-family: Aileron, sans-serif;
    font-weight: normal;
}

.studies .card {
    background-color: #fff;
    border: 1px solid #fff;
    padding: 2rem;
    margin-bottom: 2rem;
}

.studies .card:hover {
    background-color: #ECE3F2;
    border: 1px solid #CEB9DF;
}

.studies .challenge {
    font-family: Aileron, sans-serif;
    font-weight: bold;
    color: #9E72C0;
    margin: 0 0 0.5rem 0;
}