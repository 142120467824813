article {
    font-size: 1rem;
    color: #502F6A;
    line-height: 2rem;
}

summary {
    padding: 0 2rem;
}

.summaryTitle {
    text-align: center;
    margin: 3rem 0 1rem 0;
    line-height: 2.5rem;
}

.summarySep {
    text-align: center;
    position: relative;
    padding: 1rem 0;
}

.summaryRule {
    border-bottom: thin solid #9E72C0;
    position: absolute;
    height: 50%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
}

.summarySep .label {
    position: relative;
    z-index: 2;
    font-size: 0.72rem;
    text-transform: uppercase;
    font-family: aileron, sans-serif;
    text-align: center;
    background-color: #EDF3F8;
    padding: 0.2rem 1rem;
    display: inline-block;
    letter-spacing: 0.5rem;
}

.headline {
    background: rgba(0, 0, 0, 0.6);
    padding: 2rem;
}

.headline .context {
    color: #CEB9DF;
    line-height: 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: Aileron, sans-serif;
    margin-bottom: 0.75rem;
}

.headline h1 {
    color: #fff;
    line-height: 3rem;
    margin: 0 0 1.5rem 0;
}

.meta {
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem 0 0 0;
}


.metaGroup {
    padding: 0 1rem 0 0;
    flex: 1;
    min-width: 120px;
}

.metaGroupTitle {
    padding-bottom: 1rem;
    font-weight: bold;
}

.meta ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 2rem 0;
}

.meta ul li {
    font-family: aileron, sans-serif;
    font-size: 0.8rem;
    line-height: 1rem;
    padding-bottom: 1.5rem;
    text-transform: uppercase;
}

.mainColumn {
    max-width: 712px;
    padding: 0 2rem;
}

.showcase {
    background: rgba(227, 210, 228, 0.2);
    padding: 0;
    font-family: aileron, sans-serif;
    margin: 4rem 0 2rem 0;
    display: grid;
    grid-gap: var(--gutter) 0;
    grid-template-columns: var(--gutter) 1fr var(--gutter);
}

.showcase-title {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
    margin: 4rem 0 2rem 0;
    grid-column: 2 / -2;
}

:root {
    --gutter: 2rem;
}

.showcase ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

.showcase .hs {
    display: grid;
    grid-column: 1 / -1;
    grid-gap: calc(var(--gutter) * 2 / 2);
    grid-template-columns: 2rem;
    grid-auto-flow: column;

    overflow-x: scroll;
}

.showcase .landscape {
    grid-auto-columns: calc(70% - var(--gutter));
}

.showcase .portrait {
    grid-auto-columns: calc(30% - var(--gutter));
}

.showcase ol:before {
    content: '';
}

.showcase ol {
    padding-right: 2rem;
}


.fig {
    font-style: italic;
    font-weight: bold;
    font-family: "Le Monde Livre Cla Std", serif;
}

.caption {
    font-family: aileron, sans-serif;
    font-size: 0.9rem;
    line-height: 1.2rem;
    max-width: 712px;
    margin: 1rem auto 2rem auto;
    font-weight: normal;
}


.media-wrap {
    text-align: center;
}

.media-wrap > * {
    max-height: 100%;
    max-width: 100%;
}

.showcase .media-wrap > * {
    filter: drop-shadow(4px 4px 2px rgba(158, 114, 192, 0.4));
}

.keypoints li {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 2rem 0 3rem 0;
}

.keypointText {
    margin-bottom: 2rem;
}

.keypointGraphic > img {
    max-width: 100%;
    max-height: 100%;
}

.articleHeader {
    z-index: 0;
}

.articleBody {
    z-index: 0;
}


@media all and (min-width: 320px) {

    .showcase .landscape {
        grid-auto-columns: calc(80% - var(--gutter));
    }

    .showcase .portrait {
        grid-auto-columns: calc(80% - var(--gutter));
    }
}

@media all and (min-width: 420px) {
    .showcase .portrait {
        grid-auto-columns: calc(50% - var(--gutter));
    }

    .articleHeader {
        min-height: 20vh;
    }
}

@media all and (min-width: 712px) {
    .layout3col {
        display: flex;
        justify-content: center;
        padding: 0 2rem;
    }

    .articleHeader {
        min-height: 40vh;
    }

    .layout1col {
        display: flex;
        justify-content: center;
    }


    .mainColumn {
        order: 2;
        padding: 0 2rem 0 0;
    }

    .meta {
        order: 1;
        max-width: 232px;
        display: inline;
        margin-top: 3rem;
    }

    .colRight {
        order: 3;
    }

    .showcase .landscape {
        grid-auto-columns: calc(70% - var(--gutter));
    }

    .showcase .portrait {
        grid-auto-columns: calc(30% - var(--gutter));
    }

}

@media all and (min-width: 1010px) {
    .headerContent {
        width: calc(976px - 2rem);
    }

    .articleHeader {
        min-height: 70vh;
    }

    .headline {
        max-width: 512px;
        margin: 4rem 0 6rem 0;
    }

    .headline h1 {
        margin: 0 0 1rem 0;
    }

    .showcase .portrait {
        grid-auto-columns: calc(25% - var(--gutter));
    }
}

@media all and (min-width: 1420px) {
    .showcase .portrait {
        grid-auto-columns: calc(15% - var(--gutter));
    }
}